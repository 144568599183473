<template>
  <div>
    <div style="width:80%;margin:20px auto;">
      <div style="margin:20px 0;float: left;width: 40%;">满意度</div>
      <div style="margin:20px 0;float: right;width: 15%;">
        <!-- <van-radio-group v-model="dataForm.OMNick"
        direction="horizontal" >
        <van-radio name="1" @click="change"
          checked-color="#ffc002"></van-radio>

              <van-radio name="2" @click="change"
          checked-color="#ffc002"></van-radio>
      </van-radio-group> -->
        <van-checkbox-group v-model="dataForm.OMNick"
          direction="horizontal">
          <van-checkbox name="1"></van-checkbox>
        </van-checkbox-group>
        <!-- <van-switch v-model="dataForm.OMNick" active-color="#ee0a24" inactive-color="#dcdee0" size="20px" /> -->
      </div>

      <div style="margin:20px 0;float: right;width: 15%;">匿名</div>
      <van-rate v-model="dataForm.Satisfaction"
        :size="25"
        color="#ffd21e"
        void-icon="star"
        void-color="#eee"
        @change="onChange">
        <!-- <img src="http://lycaljdapi.dexian.ren//upload/images/2021/12/5c19b55e-c98.png" /> -->
      </van-rate>

      <van-field v-model="dataForm.EvalContent"
        class="textarea"
        rows="4"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入评价内容"
        show-word-limit />
      <div style="margin:20px 0;">上传照片</div>
      <van-uploader v-model="fileList"
        multiple
        capture="camera"
        class="imgs"
        :max-count="9"
        :after-read="afterRead"
        :deletable="false" />
    </div>
    <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
      <van-button round
        type="primary"
        size="normal"
        color="#ffc002"
        style="font-size: 16px; width: 95%"
        @click="showPopup()">提交</van-button>
    </div>
  </div>
</template>

<script>
import { WeEvaluateOrder } from "@/api/ylOrder.js";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { setOpenId, getOpenId } from "@/utils/auth";
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export default {
  data () {
    return {
      value: '',
      message: '',
      dataForm: {
        OMNick: [],
        Satisfaction: '',
        EvalContent: '',
        EvalImgs: [],
      },
      fileList: [],
    }
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    // this.getServiceList();
  },
  methods: {

    getServiceList: function () {
      console.log(this.$route.params.Id)
    },
    onChange (value) {
      console.log(value);
    },
    change (event) {
      console.log(event);
    },
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                // that.dataForm.EvalImgs = res.data.data
                that.dataForm.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    showPopup: function () {
      if (this.dataForm.OMNick.length == '0') {
        this.dataForm.OMNick = '0'
      } else {
        this.dataForm.OMNick = this.dataForm.OMNick + ''
      }
      if (
        this.dataForm.Satisfaction == "" ||
        this.dataForm.Satisfaction == null ||
        this.dataForm.Satisfaction == undefined
      ) {
        Toast.fail("请选择满意度");
      } else {
        this.dataForm.OrderId = this.$route.params.Id;
        this.dataForm.OpenID = getOpenId();
        this.dataForm.EvalImgs = this.dataForm.EvalImgs + "";
        this.dataForm.OMNick = Number(this.dataForm.OMNick);
        WeEvaluateOrder(this.dataForm).then((res) => {
          if (res.data.code == '0') {
            console.log(res.data);
            this.$router.push("/ylOrder/orderList");
          } else {

          }
        });
      }

    },
  },
}
</script>

<style scoped>
.van-cell {
  padding: 10px !important;
}
.textarea {
  background-color: #f9f9f9;
  border-radius: 5px;
}
.imgs {
  border-radius: 5px;
}
</style>